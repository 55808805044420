import { Routes } from '@angular/router';
import { RouteGuards } from '@ic/pwp-ui';
export const appRoutes: Routes = [
  {
   path: 'cgloginwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'CGLogin.WF.App' },
    loadChildren: () => import('./jig/CGLogin.WF.App/CGLogin.WF.App.module').then(m => m.CGLoginWFAppModule)
  },
  {
   path: 'whiteloginwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'WhiteLogin.WF.App' },
    loadChildren: () => import('./jig/WhiteLogin.WF.App/WhiteLogin.WF.App.module').then(m => m.WhiteLoginWFAppModule)
  },
  {
   path: 'cpselfsignupwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'CPSelfSignUp.WF.App' },
    loadChildren: () => import('./jig/CPSelfSignUp.WF.App/CPSelfSignUp.WF.App.module').then(m => m.CPSelfSignUpWFAppModule)
  },
  {
   path: 'cgnewpasswordresetwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'CGNewPasswordReset.WF.App' },
    loadChildren: () => import('./jig/CGNewPasswordReset.WF.App/CGNewPasswordReset.WF.App.module').then(m => m.CGNewPasswordResetWFAppModule)
  },
  {
   path: 'cgpasswordresetwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'CGPasswordReset.WF.App' },
    loadChildren: () => import('./jig/CGPasswordReset.WF.App/CGPasswordReset.WF.App.module').then(m => m.CGPasswordResetWFAppModule)
  },
  {
   path: 'cginterstitialwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'CGInterstitial.WF.App' },
    loadChildren: () => import('./jig/CGInterstitial.WF.App/CGInterstitial.WF.App.module').then(m => m.CGInterstitialWFAppModule)
  },
  {
   path: 'capgroupic10phonewfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'CapGroupIC10.Phone.WF.App' },
    loadChildren: () => import('./jig/CapGroupIC10.Phone.WF.App/CapGroupIC10.Phone.WF.App.module').then(m => m.CapGroupIC10PhoneWFAppModule)
  },
  {
   path: 'accountopeningwfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'AccountOpening.WF.App' },
    loadChildren: () => import('./jig/AccountOpening.WF.App/AccountOpening.WF.App.module').then(m => m.AccountOpeningWFAppModule)
  },
  {
   path: 'capgroupic10wfapp',
    canActivate: [RouteGuards],
    resolve: { config: RouteGuards },
    data: { workflow: 'CapGroupIC10.WF.App' },
    loadChildren: () => import('./jig/CapGroupIC10.WF.App/CapGroupIC10.WF.App.module').then(m => m.CapGroupIC10WFAppModule)
  },
  {
   pathMatch: 'full',
   path: 'CapitalGroupICXMobile',
   loadChildren: () => import('./jig/CapitalGroupICXMobile/CapitalGroupICXMobile.module')
  },
  {
   pathMatch: 'full',
   path: 'CapitalGroupICX',
   loadChildren: () => import('./jig/CapitalGroupICX/CapitalGroupICX.module')
  },
];
